<template>
    <div class="white--text grey lighten-3">
        <simple-header
            hasLeftChild
            :routes="[
                { title: 'خانه', url: '/' },
                { title: 'آموزش', url: '/' },
                { title: 'دوره صفر تا صد', url: '/' },
            ]"
            title="نرم افزار انیمیشن سازی موهو چیست؟"
        >
            <v-img
                :src="require('../../assets/img/thumb.jpg')"
                class="sp-img rounded-xl elevation-0 mr-md-auto ml-md-0 mx-auto"
            />
        </simple-header>

        <v-container>
            <v-card class="rounded-xl fill-height">
                <v-card-text class="text-justify pa-8 black--text">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                    حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                    موجود طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                    طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در
                    ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                    نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                    باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده
                    شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها
                    شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
                    خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می
                    توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و
                    شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی
                    دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن ساختگی
                    با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
                    گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و
                    سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                    کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                    کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت
                    فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت
                    بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و
                    فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان
                    امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط
                    سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی
                    دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.
                </v-card-text>
                <!-- <v-card-actions class="align-self-end">
                    <div class="d-flex flex-wrap">
                        <v-chip
                            class="ma-2 rounded-lg"
                            text-color="white"
                            label
                            color="orange darken-1"
                            v-for="i in 10"
                            :key="i"
                        >
                            محمدرضا پرویزی
                        </v-chip>
                    </div>
                </v-card-actions> -->
            </v-card>
        </v-container>
    </div>
</template>

<script>
import MyRoutesList from "../../components/MyRoutesList.vue";
import SimpleHeader from "../../components/SimpleHeader.vue";
export default {
    components: { MyRoutesList, SimpleHeader },
};
</script>

<style scoped>
.sp-img {
    /* width: 20em;
    height: 20em; */
    max-width: 300px;
    max-height: 200px;
    border: 5px white solid;
}
</style>
